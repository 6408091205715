html, body{
  height:100%;
}

/* NAV BAR */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.8);
  font-size: 1.3rem;
  padding: 0.3rem 2rem;
  font-weight: bold;
  z-index: 1;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  text-decoration: underline;
}

.navbar-toggler {
  z-index: 1;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.MuiTab-root {
  outline: none !important;
  font-size: 1.2rem !important;
  text-transform: none !important;
  font-weight: bold !important;
}

.strike { display: block; text-align: center; overflow: hidden; white-space: nowrap; }
.strike > span { position: relative; display: inline-block; margin-bottom: 5px; margin-top: 5px; }
.strike > span:before, .strike > span:after { content: ""; position: absolute; top: 50%; width: 125px; height: 2px; background: #BBB; }
.strike > span:before { right: 100%; margin-right: 15px; }
.strike > span:after { left: 100%; margin-left: 15px; }

/* Datepicker */
.react-datepicker__input-container > input {
  display: inline-block;
  font-size: 1rem;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.react-datepicker__input-container > input:focus {
  color: #495057;
  background-color: white;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  outline: none;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

[id^="ingredient"].active {
  background-color: green !important
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* STRIPO */
.esdev-app .nav {
  display: block;
}
.esdev-app .dropdown-toggle::after {
  content: none !important;
}
.esdev-app .input-group {
  width: auto !important;
}

input:disabled {
  caret-color: transparent;
}